<template>
  <v-col
    cols="12"
    sm="12"
    md="12"
    lg="12"
  >
    <v-row align="center">
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="2"
      >
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="startDate"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{on, attrs}">
            <v-text-field
              v-model="startDate"
              label="Tarih"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              hide-details
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="startDate"
            no-title
            scrollable
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="menu = false"
            >
              Kapat
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menu.save(startDate)"
            >
              Seç
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="2"
      >
        <MultiSelect
          v-model="santralModel"
          :items="santralList"
          :label="$t('santral')"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="2"
      >
        <Select
          v-model="measureSelect"
          :items="measureList"
          label=""
        />
      </v-col>

      <v-col
        sm="2"
        md="2"
        lg="1"
      >
        <v-btn
          color="primary"
          @click="santralListfunc"
        >
          Filtrele
        </v-btn>
      </v-col>
    </v-row>

    <v-row style="min-height: 300px;">
      <v-col
        v-if="santralModel.length > 0"
        cols="12"
      >
        <div
          v-for="santral in santralModel"
          :key="'d3jstotaldccurrent' + santral"
        >
          <div
            v-if="'d3jstotaldccurrent' + santral"
            :id="'d3jstotaldccurrent' + santral"
            class="pb-2 mt-4"
          />
        </div>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import * as Heatmap from '@/api/CombinerBox/Heatmap';
import moment from 'moment';
import ApexCharts from 'apexcharts';

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['stringdata'],
  data() {
    return {
      measureList: [],
      santral_items: [],
      startDate: moment().format('YYYY-MM-DD'),
      finishDate: moment().format('YYYY-MM-DD'),
      menu: false,
      modal: false,
      menu2: false,

      measureSelect: 'DCCurrent',
      componentKey: 1,
      title: '',

      inverterList: [],
      height: '',
      HeatmapData: [],
      santralModel: [],
      santralList: [],
      santralData: [],
      santralVal: {},
    };
  },
  computed: {
  },
  watch: {
    santralList() {
      const santralModel = [];
      if (this.santralList.length > 0) {
        this.santralList.forEach((element) => {
          santralModel.push(element.value);
        });
        this.santralModel = santralModel;
      }
    },
    stringdata: {
      handler() {
        setTimeout(() => {
          this.santralListfunc();
        }, 500);
      },
      deep: true,
    },
  },

  mounted() {
  },
  methods: {
    setSantral() {
      const { santralList, santralData } = this.stringdata;
      this.santralList = santralData;
      this.santralModel = santralList;
      this.santraldata = santralData;
      const santralVal = {};
      Object.keys(santralData).forEach((item) => {
        santralVal[santralData[item].value] = santralData[item];
      });
      this.santralVal = santralVal;
    },

    santralListfunc() {
      this.setSantral();
      const { santralStringboxData, measures } = this.stringdata;
      this.measureList = measures;
      Object.keys(santralStringboxData).forEach((santral) => {
        const chartId = `d3jstotaldccurrent${santral}`;
        const elemnet = document.getElementById(chartId);
        if (elemnet !== null) {
          elemnet.innerHTML = '';
        }
        this.heatmaps(santralStringboxData[santral], santral, chartId);
      });
    },

    heatmaps(santralStringboxData, santral, chartId) {
      const elemnet = document.getElementById(chartId);
      const stringList = Object.keys(santralStringboxData);

      if (elemnet !== null) {
        elemnet.innerHTML = '';
      }

      const formdata = {
        condiniton: {
          santral: this.santralVal[santral].text,
          startDate: this.startDate,
          finishDate: this.startDate,
          measures: this.measureSelect,
          STRINGBOX: stringList,
          maxcount: 3000,
          MinuteGrup: [0, 10, 20, 30, 40, 50],
        },
      };

      if (this.stringdata.prefix) {
        const { prefix, companyCode } = this.stringdata;
        formdata.condiniton.prefix = prefix;
        formdata.condiniton.companyCode = companyCode;
      }

      const temp = Heatmap.default.stringboxDCCurrentMinute(
        formdata,
        santralStringboxData,
        this.measureSelect,
      );
      temp.then((data) => {
        const margin = {
          top: 10,
          right: 150,
          bottom: 0,
          left: 150,
        };
        let itemlenght = 5;
        if (stringList.length > 0) {
          itemlenght = stringList.length;
        }
        const { windowsWidth } = this.stringdata;
        const extraWidth = 200;

        const width = windowsWidth - extraWidth - margin.left - margin.right;

        let height = itemlenght * 25;
        if (height < 300) {
          height += 100;
        }
        let unit = '';
        this.stringdata.measures.forEach((measure) => {
          if (this.measureSelect === measure.value) {
            unit = measure.unit;
          }
        });
        const parameter = {
          width,
          height,
          transform: `translate(${margin.left},${margin.top})`,
          chartId,
          chartXkey: 'time',
          chartYkey: 'STRINGBOXNAME',
          measureSelect: this.measureSelect,
          unit,
          startDate: this.startDate,
        };

        function getCellColor(item) {
          const yuzde = item.meanval;
          if (item.value > 0 && item.value < 1) {
            return 1;
          } if (item.value > 1 && item.value < yuzde) {
            // eslint-disable-next-line no-mixed-operators
            return 2;
          }
          if (item.value > yuzde) {
            // eslint-disable-next-line no-mixed-operators
            return 3;
          }
          return 1;
        }
        // eslint-disable-next-line no-shadow
        function generateData(inv, data) {
          const generatedData = [];
          data.forEach((item) => {
            const val = getCellColor(item);

            if (inv.STRINGBOXNAME === item.STRINGBOXNAME) {
              generatedData.push({
                x: item.time,
                y: val,
                meanval: item.meanval,
                mean: item.mean,
                value: item.value,
              });
            }
          });

          return generatedData;
        }
        const santralText = this.santralVal[santral].text;
        const options1 = {
          series: [],
          chart: {
            height,
            width: '99%',
            type: 'heatmap',
          },
          dataLabels: {
            enabled: false,
          },
          chartInfo: parameter,
          plotOptions: {
            show: false,
            heatmap: {
              colorScale: {
                ranges: [
                  {
                    from: 0,
                    to: 1,
                    color: '#f03a2e',
                  },
                  {
                    from: 2,
                    to: 2,
                    color: '#FF8F00',
                  },
                  {
                    from: 3,
                    to: 3,
                    color: '#32993b',
                  },
                ],
              },
            },
          },
          stroke: {
            show: true,
            colors: ['#fff'],
            width: 1,
          },
          xaxis: {
            type: 'category',
          },
          yaxis: {
            show: false,
          },
          title: {
            text: `${santralText} santrali ${this.measureSelect} karşılaştırması`,
          },
          tooltip: {
            custom({ seriesIndex, dataPointIndex, w }) {
              return `${'<div style="padding: 10px; color: #000; background-color: #fff;">'
                + '<span>'}Saat : ${w.globals.labels[dataPointIndex]}</span><br/>`
                + `<span> Cihaz :${w.config.series[seriesIndex].name}</span> <br/>`
                + `<span> ${w.config.chartInfo.measureSelect}: ${w.config.series[seriesIndex].data[dataPointIndex].value.toFixed(1)} ${w.config.chartInfo.unit}</span><br/> `
                + `<span> Ortalama: ${w.config.series[seriesIndex].data[dataPointIndex].mean.toFixed(1)} ${w.config.chartInfo.unit}</span>`
                + '</div>';
            },
          },
        };
        const item = this.stringdata.santralStringboxData[santral];
        const { clientWidth } = document.documentElement;
        if (clientWidth >= 768) {
          options1.xaxis = {
            show: false,
          };
          options1.yaxis = {
            show: true,
          };
        }
        Object.keys(item).forEach((i) => {
          const obj = {};
          obj.name = item[i].STRINGBOXNAME;
          obj.data = generateData(item[i], data);
          options1.series.push(obj);
        });

        const k = `#${chartId}`;
        const chart = new ApexCharts(document.querySelector(k), options1);
        chart.render();
      });
    },
  },
};
</script>

<style scoped>
.svgclass {
  overflow-x: auto;
  width: 100%;
 }

div.tooltip {
  position: absolute;
  text-align: center;
  width: 180px;
  min-height: 70px;
  padding: 2px;
  font: 16px sans-serif;
  border: 1px solid black;
  border-radius: 8px;
  pointer-events: none; /* keep the mouseover when over the tooltip */
}

.subtitle {
  font-family: sans-serif;
  font-size: 20px;
}

.legendPointText {
  font-size: 10px;
}

#footer {
  color: black;
  text-align: center;
}
#footer a {
  color: blue;
}
</style>
