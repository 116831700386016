<template>
  <v-dialog
    v-model="kdialog"
    width="90%"
    height="550px"
    max-height="530px"
  >
    <v-card>
      <v-card-title class="text-center text-h5 mb-0 pb-0 pt-1 mt-0">
        <span class="ml-2 text-h5 ">
          <span class="font-weight-black">
            Şirket:
          </span>
          {{ farm.companyName }} /
        </span>
        <span class="ml-2 text-h5">
          <span class="font-weight-black">
            Saha:
          </span>
          {{ farm.farmName }}
        </span>
        <v-spacer />
        <Button
          :icon="true"
          :elevation="0"
          @click="kdialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </Button>
      </v-card-title>
      <v-divider />

      <SelectedFarmErrorGroup
        ref="farmdata"
        :farmdata="farmdata"
      />
    </v-card>
  </v-dialog>
</template>
<script>
// import SelectedFarmError from '@/views/components/Notification/SelectedFarmError.vue';
import SelectedFarmErrorGroup from '@/views/components/Notification/SelectedFarmErrorGroup.vue';
// import ErrorGroup from '@/views/components/Notification/ErrorGroup.vue';

export default {
  components: {
    SelectedFarmErrorGroup,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['dialog', 'farm'],
  data: () => ({
    kdialog: false,
    farmdata: {
      windowsWidth: `${document.documentElement.clientWidth}px`,
    },
    windowsWidth: `${document.documentElement.clientWidth - 400}px`,
  }),
  watch: {
    kdialog() {
      this.farmdata = {};
      if (this.kdialog === true) {
        this.farmdata = this.farm;
        this.farmdata.windowsWidth = `${(document.documentElement.clientWidth * 75) / 100}px`;
      }
      if (this.kdialog === false) {
        this.$emit('alarmdialog');
      }
    },
    dialog() {
      if (this.dialog === true) {
        this.kdialog = true;
      }
    },
  },
  methods: {
    setKey(item) {
      this.selected = item.value;
    },
  },
};
</script>
