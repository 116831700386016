<template>
  <v-row
    dense
    class="panel-group"
  >
    <v-col
      cols="12"
      class="text-center d-flex flex-wrap"
    >
      <v-col
        v-for="(item, idx) in rest"
        :key="idx"
        cols="12"
        sm="12"
        md="6"
        lg="2"
        class="pr-0"
        transition="slide-x-transition"
      >
        <panel-group-item :item="item" />
      </v-col>
    </v-col>
  </v-row>
</template>
<script>
import PanelGroupItem from './PanelGroupItem.vue';

export default {
  name: 'PanelGroup',
  components: {
    PanelGroupItem,
  },
  props: ['rest'],
  data() {
    return {
      items: [
        {
          icon: 'mdi-solar-power',
          title: 'New Visits',
          value: 10,
          color: 'orange',
        },
        {
          icon: 'mdi-solar-power',
          title: 'New Visits',
          value: 10,
          color: 'orange',
        },
        {
          icon: 'mdi-solar-panel',
          title: 'New Visits',
          value: 10,
          color: 'orange',
        },
      ],
    };
  },
  watch: {},
  created() {},
  methods: {},
};
</script>

<style scoped>
@media (max-width: 600px) {
  .v-card {
    margin-bottom: 10px;
  }
  .panel-group .text-center {
    justify-content: center;
  }
}
</style>
