<template>
  <InverterFarmCompariton :inverterdata="inverterdata" />
</template>

<script>
import InverterFarmCompariton from '@/views/components/Inverter/InverterFarmComparitonNew.vue';
import { getInverterList } from '@/api/Request/inverter';
import helper from '@/api/helper';

export default {
  components: {
    InverterFarmCompariton,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['farmdata'],
  data() {
    return {
      inverterdata: {},
    };
  },
  computed: {},
  watch: {
    farmdata: {
      handler() {
        if (!helper.is_Empty(this.farmdata)) {
          this.selectedInverter();
        }
      },
      deep: true,
    },
  },

  mounted() {
    if (Object.keys(this.farmdata).length > 0) {
      this.selectedInverter();
    }
  },
  methods: {
    selectedInverter() {
      const payload = {
        condiniton: {
          prefix: this.farmdata.prefix,
          companyCode: this.farmdata.companyCode,
        },
      };

      this.getInverterData(payload);
    },
    getInverterData(payload) {
      const windowsWidth = document.documentElement.clientWidth - 270;
      getInverterList(payload).then((res) => {
        if (!helper.resIsEmpty(res)) {
          return;
        }

        const temp = {};
        const tempMeasures = [];
        const measuresList = [];
        const santralList = [];
        const santralData = [];
        // eslint-disable-next-line no-underscore-dangle
        const _t = ['sum', 'avg', '1'];
        Object.keys(res.data.data).forEach((i) => {
          const val = res.data.data[i];

          const {
            santral,
            santralCode,
            INVERTER,
            // eslint-disable-next-line camelcase
            general_measure_list,
          } = val;

          if (santralList.indexOf(santralCode) === -1) {
            santralList.push(santralCode);
            santralData.push({
              text: santral,
              value: santralCode,
            });
          }
          if (!helper.is_Empty(INVERTER)) {
            if (helper.is_Empty(temp[santralCode])) {
              temp[santralCode] = {};
            }
            if (helper.is_Empty(temp[santralCode][INVERTER])) {
              temp[santralCode][INVERTER] = {};
            }
            temp[santralCode][INVERTER] = val;

            if (!helper.is_Empty(general_measure_list)) {
              // eslint-disable-next-line no-shadow
              Object.keys(general_measure_list).forEach((i) => {
                if (
                  measuresList.indexOf(general_measure_list[i].value) === -1
                  && _t.indexOf(general_measure_list[i].formuletype) > -1
                ) {
                  tempMeasures.push(general_measure_list[i]);
                  measuresList.push(general_measure_list[i].value);
                }
              });
            }
          }
        });
        this.inverterdata = {
          measures: tempMeasures,
          santralInverterData: temp,
          santralList,
          santralData,
          prefix: this.farmdata.prefix,
          companyCode: this.farmdata.companyCode,
          windowsWidth,
        };
      });
    },
  },
};
</script>
