<template>
  <SelectedFarmStringboxFarmCompariton :stringdata="stringdata" />
</template>

<script>
import SelectedFarmStringboxFarmCompariton from '@/views/components/Stringbox/SelectedFarmStringboxFarmCompariton.vue';
import { getStringboxList } from '@/api/Request/stringbox';
import helper from '@/api/helper';

export default {
  components: {
    SelectedFarmStringboxFarmCompariton,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['farmdata'],
  data() {
    return {
      stringdata: {},
    };
  },
  computed: {},
  watch: {
    farmdata: {
      handler() {
        this.selectedStringbox();
      },
      deep: true,
    },
  },

  mounted() {
    if (Object.keys(this.farmdata).length > 0) {
      this.selectedStringbox();
    }
  },
  methods: {
    selectedStringbox() {
      const payload = {
        condiniton: {
          prefix: this.farmdata.prefix,
          companyCode: this.farmdata.companyCode,
        },
      };

      this.getStringboxData(payload);
    },
    getStringboxData(payload) {
      const windowsWidth = document.documentElement.clientWidth - 270;
      getStringboxList(payload).then((res) => {
        if (res.data.success === 'true') {
          if (!helper.is_Empty(res.data.data)) {
            // context.commit('SET_INVERTERDATA', res.data.data);
            const temp = {};
            const tempMeasures = [];
            const measuresList = [];
            const santralList = [];
            const santralData = [];

            // eslint-disable-next-line no-underscore-dangle
            const _t = ['sum', 'avg', '1'];
            Object.keys(res.data.data).forEach((i) => {
              const {
                santral,
                santralCode,
                STRINGBOX,
                // eslint-disable-next-line camelcase
                general_measure_list,
              } = res.data.data[i];
              if (santralList.indexOf(santralCode) === -1) {
                santralList.push(santralCode);
                santralData.push({
                  text: santral,
                  value: santralCode,
                });
              }
              if (!helper.is_Empty(STRINGBOX)) {
                if (helper.is_Empty(temp[santralCode])) {
                  temp[santralCode] = {};
                }
                if (helper.is_Empty(temp[santralCode][STRINGBOX])) {
                  temp[santralCode][STRINGBOX] = {};
                }
                temp[santralCode][STRINGBOX] = res.data.data[i];
                Object.keys(general_measure_list).forEach((y) => {
                  if (
                    measuresList.indexOf(general_measure_list[y].value)
                    === -1
                    && _t.indexOf(general_measure_list[y].formuletype) > -1
                  ) {
                    tempMeasures.push(general_measure_list[y]);
                    measuresList.push(general_measure_list[y].value);
                  }
                });
              }
            });
            this.stringdata = {
              measures: tempMeasures,
              santralStringboxData: temp,
              santralList,
              santralData,
              prefix: this.farmdata.prefix,
              companyCode: this.farmdata.companyCode,
              windowsWidth,
            };
          }
        }
      });
    },
  },
};
</script>
