<template>
  <v-hover>
    <template v-slot:default="{hover}">
      <v-card :class="['mr-1', {'elevation-12': hover, 'elevation-2': !hover}]">
        <v-list-item three-line>
          <v-list-item-avatar size="45">
            <v-icon
              :size="40"
              :color="item.color"
            >
              {{ item.icon }}
            </v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="text-subtitle-1 font-weight-light">
              {{ item.title }}
            </v-list-item-title>
            <v-list-item-title class="text-h6 font-weight-bold">
              {{ item.value }} {{ item.unit }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </template>
  </v-hover>
</template>

<script>
export default {
  name: 'PanelGroupItem',
  components: {},
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {},
};
</script>
