<template>
  <v-row>
    <v-col
      cols="12"
      sm="12"
      md="12"
      lg="12"
    >
      <v-card class="mb-15 pb-10 px-4">
        <v-row align="center">
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="2"
          >
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="startDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{on, attrs}">
                <v-text-field
                  v-model="startDate"
                  label="Tarih"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  hide-details
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="startDate"
                no-title
                scrollable
              >
                <v-spacer />
                <v-btn
                  text
                  color="primary"
                  @click="menu = false"
                >
                  Kapat
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(startDate)"
                >
                  Seç
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="2"
          >
            <MultiSelect
              v-model="santralModel"
              :items="santralList"
              :label="$t('santral')"
            />
          </v-col>

          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="2"
          >
            <Select
              v-model="measureSelect"
              :items="inverterdata.measures"
              label=""
              hide-details
            />
          </v-col>

          <v-col
            sm="2"
            md="2"
            lg="1"
          >
            <v-btn
              color="primary"
              @click="startFunc"
            >
              Filtrele
            </v-btn>
          </v-col>
        </v-row>

        <v-row style="min-height: 300px;">
          <v-col cols="12">
            <div
              v-for="(santral) in inverterdata.santralList"
              :key="'d3jstotalinverter' + santral"
            >
              <div
                :id="'d3jstotalinverter' + santral"
              />
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import * as Heatmap from '@/api/Inverter/Heatmap';
import moment from 'moment';
import ApexCharts from 'apexcharts';

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['inverterdata'],
  data() {
    return {
      measureList: [],
      santral_items: [],
      startDate: moment().format('YYYY-MM-DD'),
      finishDate: moment().format('YYYY-MM-DD'),
      menu: false,
      modal: false,
      menu2: false,
      measureSelect: 'ACPower',
      componentKey: 1,
      title: '',
      santralModel: [],
      inverterList: [],
      height: '',
      HeatmapData: [],
      santralList: [],
      santralVal: {},
      santraldata: {},
      santralInverterData: {},
    };
  },
  computed: {
  },
  watch: {
    inverterdata: {
      handler() {
        setTimeout(() => {
          this.startFunc();
        }, 500);
      },
      deep: true,
    },
  },

  mounted() {},
  methods: {

    setSantral() {
      const { santralList, santralData } = this.inverterdata;
      this.santralList = santralData;
      this.santralModel = [santralList[0]];
      this.santraldata = santralData;
      const santralVal = {};
      Object.keys(santralData).forEach((item) => {
        santralVal[santralData[item].value] = santralData[item];
      });
      this.santralVal = santralVal;
    },

    startFunc() {
      this.setSantral();
      const { santralInverterData } = this.inverterdata;

      Object.keys(santralInverterData).forEach((santral) => {
        const chartId = `d3jstotalinverter${santral}`;
        const elemnet = document.getElementById(chartId);

        if (elemnet !== null) {
          elemnet.innerHTML = '';
        }
        this.heatmaps(santralInverterData[santral], santral);
      });
    },

    heatmaps(inverterrawdata, santral) {
      const inverterList = Object.keys(inverterrawdata);
      const formdata = {
        condiniton: {
          santral: this.santralVal[santral].text,
          startDate: this.startDate,
          finishDate: this.startDate,
          measures: this.measureSelect,
          INVERTER: inverterList,
          maxcount: 3000,
          MinuteGrup: [0, 10, 20, 30, 40, 50],
        },
      };
      if (this.inverterdata.prefix) {
        formdata.condiniton.prefix = this.inverterdata.prefix;
        formdata.condiniton.companyCode = this.inverterdata.companyCode;
      }

      const temp = Heatmap.default.inverterMinuteDataHeatmap(
        formdata,
        inverterrawdata,
        this.measureSelect,
      );
      temp.then((data) => {
        const chartId = `d3jstotalinverter${santral}`;
        const elemnet = document.getElementById(chartId);

        if (elemnet !== null) {
          elemnet.innerHTML = '';
        }
        const margin = {
          top: 10,
          right: 150,
          bottom: 0,
          left: 150,
        };

        let itemlenght = 5;
        if (inverterList.length > 0) {
          itemlenght = inverterList.length;
        }

        let height = itemlenght * 25;
        if (height < 300) {
          height += 100;
        }

        const { windowsWidth } = this.inverterdata;
        const extraWidth = 200;

        const width = windowsWidth - extraWidth - margin.left - margin.right;

        let unit = '';
        this.inverterdata.measures.forEach((measure) => {
          if (this.measureSelect === measure.value) {
            unit = measure.unit;
          }
        });
        const parameter = {
          width: width + 20,
          height: height + 10,
          transform: `translate(${margin.left},${margin.top})`,
          chartId,
          chartXkey: 'time',
          chartYkey: 'INVERTERNAME',
          measureSelect: this.measureSelect,
          unit,
          startDate: this.startDate,
        };

        function getCellColor(item) {
          if (item.value < 1) {
            return 1;
          } if (item.value < item.meanval) {
            // eslint-disable-next-line no-mixed-operators
            return 2;
          }
          // eslint-disable-next-line no-mixed-operators
          return 3;
        }
        // eslint-disable-next-line no-shadow
        function generateData(inv, data) {
          const generatedData = [];
          data.forEach((item) => {
            const val = getCellColor(item);
            if (inv.INVERTERNAME === item.INVERTERNAME) {
              generatedData.push({
                x: item.time,
                y: val,
                meanval: item.meanval,
                mean: item.mean,
                value: item.value,
              });
            }
          });

          return generatedData;
        }

        const santralText = this.santralVal[santral].text;
        const { clientWidth } = document.documentElement;

        const options1 = {
          series: [],
          chart: {
            height,
            width: '95%',
            type: 'heatmap',
          },
          dataLabels: {
            enabled: false,
          },
          chartInfo: parameter,
          plotOptions: {
            show: false,
            heatmap: {
              colorScale: {
                ranges: [
                  {
                    from: 0,
                    to: 1,
                    color: '#f03a2e',
                  },
                  {
                    from: 2,
                    to: 2,
                    color: '#FF8F00',
                  },
                  {
                    from: 3,
                    to: 3,
                    color: '#32993b',
                  },
                ],
              },
            },
          },
          stroke: {
            show: true,
            colors: ['#fff'],
            width: 1,
          },
          xaxis: {
            type: 'category',
            show: true,
          },
          yaxis: {
            show: false,
          },
          title: {
            text: `${santralText} santrali ${this.measureSelect} karşılaştırması`,
          },
          tooltip: {
            custom({ seriesIndex, dataPointIndex, w }) {
              return `${'<div style="padding: 10px; color: #000; background-color: #fff;">'
                + '<span>'}Saat : ${w.globals.labels[dataPointIndex]}</span><br/>`
                + `<span> Cihaz :${w.config.series[seriesIndex].name}</span> <br/>`
                + `<span> ${w.config.chartInfo.measureSelect}: ${w.config.series[seriesIndex].data[dataPointIndex].value.toFixed(1)} ${w.config.chartInfo.unit}</span><br/> `
                + `<span> Ortalama: ${w.config.series[seriesIndex].data[dataPointIndex].meanval.toFixed(1)} ${w.config.chartInfo.unit}</span>`
                + '</div>';
            },
          },
        };
        if (clientWidth >= 768) {
          options1.xaxis = {
            show: false,
          };
          options1.yaxis = {
            show: true,
          };
        }
        const item = this.inverterdata.santralInverterData[santral];

        Object.keys(item).forEach((i) => {
          const obj = {};
          obj.name = item[i].INVERTERNAME;
          obj.data = generateData(item[i], data);
          options1.series.push(obj);
        });

        const k = `#${chartId}`;
        const chart = new ApexCharts(document.querySelector(k), options1);
        chart.render();
      });
    },
  },
};
</script>

<style scoped>
.svgclass {
  overflow-x: auto;
  width: 100%;
  height: 375px;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.subtitle {
  font-family: sans-serif;
  font-size: 20px;
}

.legendPointText {
  font-size: 10px;
}

#footer {
  color: black;
  text-align: center;
}
#footer a {
  color: blue;
}
</style>
