<template>
  <div>
    <div class="col-12 d-flex mt-0 mb-0 pb-0 pl-0 pt-0 ml-0 mr-0 pr-2">
      <div
        id="santralList"
        class="col-2 ml-0 mr-0 pr-0 pl-2 mt-1 pt-0 ml-0 pl-0 pb-0 mb-0"
      >
        <v-card
          ref="selectedFarmData"
          class="ml-0 mr-0 pr-0 pl-0 pb-0 mb-0"
        >
          <div>
            <div class="font-weight-bold ml-2 mt-2 pl-2 pt-2 pb-2">
              Santral Listesi
            </div>
            <!--
              <v-text-field
              v-model="search"
              outlined
              class="ml-2 mr-2"
              dense
              label="Ara"
              append-outer-icon="mdi-file-find"
              hide-details
              @click:append-outer="searchFarm"
            />
          -->
          </div>
          <v-simple-table
            dense
            height="650px"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left font-weight-black">
                    Ülke
                  </th>
                  <th class="text-left font-weight-black">
                    İsim
                  </th>
                  <th class="text-left font-weight-black">
                    Seç
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in desserts"
                  :key="item.companyCode"
                  :class="selectedFarmData.companyCode === item.companyCode ? 'selectedfarm' : ''"
                >
                  <td>{{ item.Country }}</td>
                  <td>{{ item.companyName }}/{{ item.farmName }}</td>
                  <td>
                    <v-btn
                      small
                      class=""
                      icon
                      color="blue darken-1"
                      @click="selectedFarm(item)"
                    >
                      <v-icon>mdi-launch</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </div>
      <div class="col-10 mb-0 pb-0 pr-0 mr-2">
        <v-card
          rounded
          class=""
        >
          <v-card-text class="pb-1 pt-1 pl-1 pr-1">
            <div id="map" />
            <v-hover>
              <template v-slot:default="{hover}">
                <div
                  v-show="windowshow"
                  id="kutu1"
                  :class="`elevation-${hover ? 12 : 2}`"
                  class="rounded-xl"
                >
                  <div class="mt-0 mb-0 pt-0 pb-0">
                    <v-btn
                      height="20px"
                      class="mt-0 mb-0 pt-0 pb-0"
                      color="primary"
                      icon
                      @click="windowshow = false"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-divider />
                  </div>

                  <div class="d-flex">
                    <div class="col-5 text-left mb-0 mt-0 pt-1 pb-1">
                      Şirket Adı
                    </div>
                    <div
                      class="
                        col-7
                        text-left
                         mb-0
                        mt-0
                        pt-1
                        pb-1
                      "
                    >
                      : {{ selectedFarmData.companyName }}
                    </div>
                  </div>
                  <v-divider />

                  <div class="d-flex">
                    <div class="col-5 text-left mb-0 mt-0 pt-1 pb-1">
                      Saha Adı
                    </div>
                    <div
                      class="
                        col-7
                        text-left
                         mb-0
                        mt-0
                        pt-1
                        pb-1
                      "
                    >
                      : {{ selectedFarmData.farmName }}
                    </div>
                  </div>
                  <v-divider />

                  <v-divider />

                  <div class="d-flex">
                    <div class="col-5 text-left mb-0 mt-0 pt-1 pb-1">
                      Anlık Üretim
                    </div>
                    <div
                      class="
                        col-7
                        text-left
                         mb-0
                        mt-0
                        pt-1
                        pb-1
                      "
                    >
                      : {{ selectedFarmData.IstantaneousPower }} kW
                    </div>
                  </div>

                  <v-divider />

                  <div class="d-flex dense">
                    <div class="col-5 text-left mb-0 mt-0 pt-1 pb-1">
                      Günlük Üretim
                    </div>
                    <div
                      class="
                        col-7
                        text-left
                         mb-0
                        mt-0
                        pt-1
                        pb-1
                      "
                    >
                      : {{ selectedFarmData.DailyEnergy }} kWp
                    </div>
                  </div>

                  <v-divider />
                  <div class="d-flex">
                    <div class="col-5 text-left mb-0 mt-0 pt-1 pb-1">
                      Açık Uyarılar:
                    </div>
                    <div
                      class="
                        col-7
                        text-left
                         mb-0
                        mt-0
                        pt-1
                        pb-1
                      "
                    >
                      : {{ selectedFarmData.error }}
                    </div>
                  </div>
                  <v-divider />
                  <div
                    v-if="selectedFarmData.NominalPlantPower"
                    class="d-flex"
                  >
                    <div class="col-5 text-left mb-0 mt-0 pt-1 pb-1">
                      Kurulu Güç
                    </div>
                    <div
                      class="
                        col-7
                        text-left
                         mb-0
                        mt-0
                        pt-1
                        pb-1
                      "
                    >
                      : {{ selectedFarmData.NominalPlantPower.value }}
                      {{ selectedFarmData.NominalPlantPower.unit }}
                    </div>
                  </div>
                  <v-divider />

                  <div class="d-flex">
                    <div class="col-5 text-left mb-0 mt-0 pt-1 pb-1">
                      Hava
                    </div>
                    <div
                      class="
                        col-7
                        text-left

                        mb-0
                        mt-0
                        pt-1
                        pb-1

                      "
                    >
                      <v-col
                        class="mb-0 pb-0 d-flex pt-0 mt-0"
                        cols="6"
                      >
                        <v-img
                          :src="selectedFarmData.icon"
                          alt="Sunny image"
                          width="30"
                        />
                        <span class="mr-3 text-h4"> {{ selectedFarmData.temp }}&deg;C </span>
                      </v-col>
                    </div>
                  </div>
                  <v-divider />

                  <v-divider />
                  <div class="">
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="farmGraphic('screen')"
                    >
                      Anlık
                    </v-btn>

                    <v-btn
                      color="blue darken-1"
                      text
                      @click="farmGraphic('alarm')"
                    >
                      Hata
                    </v-btn>

                    <v-btn
                      color="blue darken-1"
                      text
                      @click="farmOpen(selectedFarmData, 'detail')"
                    >
                      Detay
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="farmOpen(selectedFarmData, 'setting')"
                    >
                      Firma Yönetim
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="farmOpen(selectedFarmData, 'ayar')"
                    >
                      Saha Yönetim
                    </v-btn>
                  </div>
                </div>
              </template>
            </v-hover>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <PanelGroup
      v-if="componentsize"
      :rest="rest"
    />

    <Screen
      :dialog="graphicdialog"
      :farm="selectedFarmData"
      @screendialog="screendialogclose"
    />
    <Alarm
      :dialog="errordialog"
      :farm="selectedFarmData"
      @alarmdialog="alarmdialogclose"
    />
  </div>
</template>
<script>
import NotifyModal from '@/views/components/Notification/NotifyModal.vue';
import moment from 'moment';
import { init } from '@/utils/gmaps';
import { getFarmDataMinute } from '@/api/Request/farmdata';
import Screen from '@/views/components/Farm/Data/Screen.vue';
import Alarm from '@/views/components/Farm/Data/Alarm.vue';
import helper from '@/api/helper';
import PanelGroup from '@/views/components/Farm/PanelGroup.vue';
import common from '@/api/component/common';

export default {
  name: 'App',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    NotifyModal,
    Screen,
    Alarm,
    PanelGroup,
  },
  data() {
    return {
      googleData: [],
      locations: [],
      markers: [],
      markerCluster: null,
      santralMapDetail: false,
      startDate: moment().format('YYYY-MM-DD'),
      finishDate: moment().format('YYYY-MM-DD'),
      google: null,
      map: null,
      myMarkers: [],
      isLoadingMyMarkers: false,
      isClickFilterButton: false,
      items: 5,
      santral_items: [],
      itemsPerPage: 500,
      tableModel: [],
      santral_headers: [],
      alarmDialog: false,
      selectedItemprefix: null,
      prefix: null,
      desserts: [],
      selectedFarmData: {},
      windowshow: false,
      graphicdialog: false,
      errordialog: false,
      componentsize: 0,
      search: '',
      totalVal: {
        NominalPlantPower: 0,
        IstantaneousPower: 0,
        DailyEnergy: 0,
        TotalEnergy: 0,
        TheoreticalPower: 0,
        PerformanceRatio: 0,
        BarrelsofOil: 0,
        CO2Avoided: 0,
        EquivalentTrees: 0,
        connectTime: '',
      },
      image: undefined,
      imageUrl: '',
    };
  },
  computed: {},
  watch: {},
  async created() {
    this.google = await init();
    const options = {
      zoom: 7,
      center: { lat: 35, lng: 40 },
      gestureHandling: 'greedy',
    };

    const { google } = this;
    this.map = new google.maps.Map(document.getElementById('map'), options);
    this.initializeGoogleMaps();
  },
  mounted() {
    const kutu1 = document.getElementById('kutu1');
    kutu1.style.left = '10px';
    setInterval(this.callGoogleMaps, 300000);
  },
  methods: {
    createImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imageUrl = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    onFileChange(file) {
      if (!file) {
        return;
      }
      this.createImage(file);
    },
    farmOpen(item, event) {
      const storage = window.localStorage;
      const temp = {
        companyCode: item.companyCode,
        prefix: item.prefix,
      };
      const encoded = btoa(JSON.stringify(temp));
      this.$store.dispatch('setCompany', temp);
      storage.setItem('user_company', encoded);

      this.homePage(item, event);
    },
    async homePage(item, event) {
      this.selectUser(item);

      if (event === 'detail') {
        window.location.href = '/dashboard';
      }

      if (event === 'setting') {
        window.location.href = '/admin/setting/info';
      }
      if (event === 'ayar') {
        window.location.href = '/admin/station/company';
      }
    },
    selectUser(item) {
      const storage = window.localStorage;
      storage.setItem('selectedprefix', window.btoa(item.prefix));

      const temp = {
        companyCode: item.companyCode,
        prefix: item.prefix,
      };

      this.$store.dispatch('setSelectedFarm', temp);
      storage.setItem('user_company', window.btoa(JSON.stringify(temp)));
    },
    setTotalStats(item) {
      // this.rest = item;DailyEnerg
      this.rest = [
        {
          icon: 'mdi-solar-panel',
          title: 'Kurulu Güç',
          value: item.NominalPlantPower.toFixed(2),
          color: 'orange',
          unit: 'kWp',
        },
        {
          icon: 'mdi-solar-power',
          title: 'Toplam Üretim',
          value: item.TotalEnergy.toFixed(2),
          color: 'orange',
          unit: 'MW',
        },
        {
          icon: 'mdi-solar-power',
          title: 'Günlük Enerji',
          value: item.DailyEnergy.toFixed(2),
          color: 'orange',
          unit: 'kWh',
        },
        {
          icon: 'mdi-solar-power',
          title: 'Anlık Üretim',
          value: item.IstantaneousPower.toFixed(2),
          color: 'orange',
          unit: 'kWh',
        },
        /*  {
            icon: "mdi-flash",
            title: "Performans",
            value: item.PerformanceRatio,
            color: "red",
            unit: "%",
          }, */
        {
          icon: 'mdi-fire',
          title: 'CO2',
          value: item.CO2Avoided.toFixed(2),
          color: 'primary',
          unit: '',
        },
        {
          icon: 'mdi-pine-tree',
          title: 'Ağaç Eşdeğer',
          value: item.EquivalentTrees.toFixed(2),
          color: 'green',
          unit: '',
        },
      ];
      this.$nextTick(() => {
        this.componentsize += 1;
      });
    },
    screendialogclose() {
      this.graphicdialog = false;
    },
    alarmdialogclose() {
      this.errordialog = false;
    },
    farmGraphic(type) {
      if (type === 'screen') {
        this.graphicdialog = true;
      }

      if (type === 'alarm') {
        this.errordialog = true;
      }
    },
    selectedFarm(item) {
      this.windowshow = true;
      // eslint-disable-next-line no-param-reassign
      this.selectedFarmData = item;
      const { map } = this;
      map.setCenter({
        lat: item.location.lat,
        lng: item.location.lng,
      });
      map.setZoom(9);
    },
    searchFarm() {
      // console.log(this.search);
      // this.callGoogleMaps();
    },
    initializeGoogleMaps() {
      this.isLoadingMyMarkers = true;
      this.callGoogleMaps();
    },
    selectedItem(item) {
      this.selectedItemprefix = item.prefix;
      this.alarmDialog = true;
    },
    callGoogleMaps() {
      try {
        const { google, map } = this;
        map.setCenter({
          lat: 39.191746,
          lng: 35.5661104,
        });
        map.setZoom(7);

        const formdata = {
          condiniton: {
            status: 'Active',
            date: 'last',
          },
        };
        if (this.search !== undefined) {
          formdata.condiniton.farmName = this.search;
        }
        const self = this;

        getFarmDataMinute(formdata).then((res) => {
          if (res.status !== 200) {
            return;
          }
          if (res.data.success === 'false') {
            return;
          }
          this.desserts = res.data.data;

          Object.keys(res.data.data).forEach((i) => {
            this.setParameters(res.data.data[i]);
            this.addMarker(res.data.data[i], google, map, self);
          });
          self.totalVal.TotalEnergy = helper.kwhToMwh(self.totalVal.TotalEnergy);
          self.totalVal = res.data.total;

          self.setTotalStats(self.totalVal);
        });
      } catch (error) {
        console.error(error);
      }
    },
    setParameters(data) {
      Object.keys(this.totalVal).forEach((m) => {
        let v = 0;
        try {
          if (typeof data[m] === 'object') {
            if (!helper.is_Empty(data[m])) {
              if ('value' in data[m]) {
                if (!helper.is_Empty(data[m].value)) {
                  v = data[m].value;
                }
              }
            }
          } else {
            v = data[m];
          }

          try {
            this.totalVal[m] = helper.setFixed(v, 1);
          } catch (e) {
            this.totalVal[m] = 0;
          }
        } catch (e) {
          this.totalVal[m] = 0;
        }
      });
      this.componentsize += 1;
    },
    locationControl(props) {
      if ('location' in props) {
        if (props.location === null || props.location === undefined) {
          return 'false';
        }
        if ('lat' in props.location && 'lng' in props.location) {
          if (props.location.lat === null || props.location.lng === undefined) {
            return 'false';
          }
          return 'true';
        }
      }
      return 'false';
    },

    addMarker(props, google, map, self) {
      const control = this.locationControl(props);

      if (control === 'false') {
        return;
      }

      // eslint-disable-next-line global-require
      let icon = require('@/views/images/icon/solar_panel_icon_success.svg');

      if (props.error > 0) {
        // eslint-disable-next-line global-require
        icon = require('@/views/images/icon/solar_panel_icon_error.svg');
      }

      const coords = {
        lat: props.location.lat,
        lng: props.location.lng,
      };

      const marker = new google.maps.Marker({
        position: coords,
        label: {
          color: 'white',
          fontSize: '10px',
        },
        animation: google.maps.Animation.DROP,
        icon: {
          url: icon,
          scaledSize: new google.maps.Size(30, 30),
          fillColor: '#0000ff',
        /*   origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(50, 50), */
        },
        flat: true,
        optimized: false,
        visible: true,
        map,
      });

      // eslint-disable-next-line no-param-reassign
      props.DailyEnergy = props.DailyEnergy ? props.DailyEnergy : 0;
      // eslint-disable-next-line no-param-reassign
      props.IstantaneousPower = props.IstantaneousPower ? props.IstantaneousPower : 0;
      // eslint-disable-next-line no-param-reassign
      props.TheoreticalPower = props.TheoreticalPower ? props.TheoreticalPower : 0;

      // eslint-disable-next-line no-param-reassign
      props.Error = props.Error ? props.Error : 0;
      // eslint-disable-next-line no-param-reassign
      props.Warning = props.Warning ? props.Warning : 0;
      // eslint-disable-next-line no-param-reassign
      props.temp = '';
      // eslint-disable-next-line no-param-reassign
      props.icon = '';
      if ('weather' in props) {
        // eslint-disable-next-line no-param-reassign, radix
        props.temp = parseInt(props.weather[0].temp);
        // eslint-disable-next-line no-param-reassign
        props.icon = self.setTest(props.weather[0]);
      }

      google.maps.event.addListener(marker, 'click', () => {
        // eslint-disable-next-line no-param-reassign
        self.windowshow = true;
        // eslint-disable-next-line no-param-reassign
        self.selectedFarmData = props;
      });
    },
    setTest(data) {
      const { description } = data.weather[0];
      const { date } = data;
      // eslint-disable-next-line camelcase
      const sunrise_s = data.sunrise;
      // eslint-disable-next-line camelcase
      const sunset_s = data.sunset;

      // eslint-disable-next-line camelcase
      const sunrise = moment(sunrise_s * 1000).format('YYYY-MM-DD HH:mm:ss');
      // eslint-disable-next-line camelcase
      const sunset = moment(sunset_s * 1000).format('YYYY-MM-DD HH:mm:ss');
      let type = 'day';
      if (date < sunrise) {
        type = 'night';
      }
      if (date > sunset) {
        type = 'night';
      }

      return common.weaterIcons(type, description);
    },
  },
};
</script>
<style scoped>
#map {
  height: 680px;
  width: 100%;
}

#contents {
  height: 300px;
  width: 200px;
}

#svgsantralcomp {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

#kutu1 {
  background-color: #ffffff;
  height: 350px;
  width: 330px;
  position: absolute;
  z-index: 20;
  font-weight: 500;
  font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
  font-size: clamp(0.9rem, 0.9vw, 1.2rem);
  top: 80px;
  line-height: 1.28571429em;
  color: #303030;
}
</style>
