<template>
  <v-col
    cols="12"
    sm="12"
    md="12"
    lg="12"
  >
    <v-row align="center">
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="2"
      >
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="startDate"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{on, attrs}">
            <v-text-field
              v-model="startDate"
              label="Tarih"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="startDate"
            no-title
            scrollable
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="menu = false"
            >
              Kapat
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menu.save(startDate)"
            >
              Seç
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col
        cols="12"
        sm="3"
        md="2"
        lg="1"
      >
        <v-select
          v-model="chartTypeModel"
          :items="chartType"
          label="Grafik"
        />
      </v-col>

      <v-col
        sm="2"
        md="2"
        lg="1"
      >
        <v-btn
          color="primary"
          @click="selectedSensor"
        >
          Filtrele
        </v-btn>
      </v-col>
    </v-row>

    <v-col
      v-if="chartId"
      ref="renderComponent"
      cols="12"
      style="min-height: 300px;"
    >
      <div v-if="chartId">
        <echart
          :id="chartId"
          class-name="widget-content"
          height="350px"
          width="100%"
          :chart-data="livehourdata"
        />
      </div>
    </v-col>
  </v-col>
</template>

<script>
import Echart from '@/views/components/Echart/Chart.vue';
import { getFarmSensorChartOpts } from '@/api/SensorBox/ChartOption';
import moment from 'moment';
import helper from '@/api/helper';
import { getFarmSensorMinuteData, getSensorList } from '@/api/Request/sensor';

export default {
  name: 'SensorBoXDailyChart',
  components: {
    Echart,
  },
  props: ['farmdata'],
  data: () => ({
    santral: '',
    componentKey: 1,
    startDate: moment().format('YYYY-MM-DD'),
    dailydata: {},
    monthlydata: {},
    yearlydata: {},
    year: moment().format('YYYY'),
    menu: false,
    modal: false,
    menu2: false,
    alarmDialog: false,
    livehourdata: {},
    inverterList: {},
    sensorInfo: {},
    chartType: ['line', 'bar', 'line2'],
    chartTypeModel: 'line2',
    sensorMeasureList: {},
    sensorList: [],
    chartId: null,
  }),
  computed: {},
  watch: {
    farmdata: {
      handler() {
        this.selectedSensor();
      },
      deep: true,
    },
  },
  created() {},
  mounted() {
    if (Object.keys(this.farmdata).length > 0) {
      this.selectedSensor();
    }
  },
  methods: {
    selectedSensor() {
      this.chartId = null;
      if (this.farmdata === undefined || this.farmdata === null) {
        return;
      }

      this.chartId = `santral-sensor-vals${this.farmdata.prefix}`;

      const payload = {
        condiniton: {
          prefix: this.farmdata.prefix,
          companyCode: this.farmdata.companyCode,
        },
      };
      this.getSensorData(payload);
    },
    getSensorData(payload) {
      getSensorList(payload).then((res) => {
        if (res.data.success === 'true') {
          if (!helper.is_Empty(res.data.data)) {
            this.getDailyMinuteEnergy();
          }
        }
      });
    },
    getDailyMinuteEnergy() {
      this.finishDate = moment(this.startDate).format('YYYY-MM-DD');
      const text = '';

      const { sensorMeasures } = this.$store.getters;

      const params = {
        condiniton: {
          date: this.startDate,
          prefix: this.farmdata.prefix,
          companyCode: this.farmdata.companyCode,
        },
      };
      this.livehourdata = {};

      const stepTime = helper.getSantralStep(this.santral);
      getFarmSensorMinuteData(params).then((_rawData) => {
        this.livehourdata = getFarmSensorChartOpts(
          _rawData,
          stepTime,
          sensorMeasures,
          text,
          this.chartTypeModel,
        );
      });
    },

    forceRerender() {
      this.renderComponent = false;
      this.$nextTick().then(() => {
        this.renderComponent = true;
      });
    },
  },
};
</script>

<style scoped>
.widget-content {
  width: 100%;
}
</style>
