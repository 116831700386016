<template>
  <Dialog
    v-model="kdialog"
    width="95%"
    min_hight="400px"
    :scrollable="true"
    max-height="100%"
    :dense="true"
  >
    <Card>
      <!-- Yüksekliği ekrana göre ayarlayın -->
      <v-card-title>
        <div>
          <span class="ml-2 text-h5 ">
            <span class="font-weight-black">
              Şirket:
            </span>
            {{ farm.companyName }} /
          </span>
          <span class="ml-2 text-h5">
            <span class="font-weight-black">
              Saha:
            </span>
            {{ farm.farmName }}
          </span>
        </div>

        <v-spacer />
        <Button
          :icon="true"
          :elevation="0"
          @click="kdialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </Button>
      </v-card-title>
      <v-divider />

      <div class="d-flex flex-column">
        <v-tabs
          v-model="toggle_exclusive"
          color="white"
          height="30px"
          fixed-tabs
          background-color="indigo"
          dark
        >
          <v-tab
            v-for="btn in toggleList"
            :key="btn.value"
            dense
            :href="`#tab-${btn.value}`"
          >
            {{ btn.text }}
          </v-tab>
        </v-tabs>
      </div>

      <v-tabs-items v-model="toggle_exclusive">
        <v-tab-item value="tab-farm">
          <DailyMinuteData
            v-if="toggle_exclusive === 'tab-farm'"
            ref="farmdata[prefix]"
            :farmdata="farmdata[prefix]"
          />
        </v-tab-item>
        <v-tab-item value="tab-inverter">
          <SelectedFarmInverter
            v-if="toggle_exclusive === 'tab-inverter'"
            ref="farmdata[prefix]"
            :farmdata="farmdata[prefix]"
          />
        </v-tab-item>

        <v-tab-item value="tab-string">
          <SelectedFarmStringbox
            v-if="toggle_exclusive === 'tab-string'"
            ref="farmdata[prefix]"
            :farmdata="farmdata[prefix]"
          />
        </v-tab-item>

        <v-tab-item value="tab-sensor">
          <SelectedFarmSensor
            v-if="toggle_exclusive === 'tab-sensor'"
            ref="farmdata[prefix]"
            :farmdata="farmdata[prefix]"
          />
        </v-tab-item>
      </v-tabs-items>
    </Card>
  </Dialog>
</template>

<script>
import DailyMinuteData from '@/views/components/Dashboard/DailyMinuteData.vue';
// import SelectedFarmInverter from '@/views/components/Inverter/SelectedFarmInverter.vue';
import SelectedFarmInverter from '@/views/components/Inverter/SelectedFarmInverter.vue';
import SelectedFarmStringbox from '@/views/components/Stringbox/SelectedFarmStringbox.vue';
import SelectedFarmSensor from '@/views/components/Sensor/SelectedFarmSensor.vue';

export default {
  components: {
    SelectedFarmInverter,
    DailyMinuteData,
    SelectedFarmStringbox,
    SelectedFarmSensor,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['dialog', 'farm'],
  data: () => ({
    toggle_exclusive: 0,
    selected: 'farm',
    kdialog: false,
    prefix: null,
    toggleList: [
      { text: 'Saha', value: 'farm' },
      { text: 'İnverter', value: 'inverter' },
      { text: 'String', value: 'string' },
      { text: 'Sensör', value: 'sensor' },
      // { text: 'Osos', value: 'osos' },
    ],
    farmdata: {
      windowsWidth: `${document.documentElement.clientWidth}px`,
    },
    windowsWidth: `${document.documentElement.clientWidth - 200}px`,
  }),
  watch: {
    kdialog() {
      this.farmdata = {};
      if (this.kdialog === true) {
        const { prefix } = this.farm;
        this.prefix = prefix;
        this.farmdata[prefix] = this.farm;
        this.farmdata[prefix].windowsWidth = `${(document.documentElement.clientWidth * 85)
          / 100}px`;
      }
      if (this.kdialog === false) {
        this.farmdata = {};
        this.prefix = null;
        this.$emit('screendialog');
      }
    },
    dialog() {
      if (this.dialog === true) {
        this.kdialog = true;
      }
    },
  },
  methods: {
    setKey(item) {
      this.selected = item.value;
    },
  },
};
</script>
